import React from "react";
import './Portfolio.css';
import PageHeadline from './PageHeadline'
import Headline from './Headline'
import Galerie from './Galerie'


function Portfolio() {

  return (
    <div>
      <PageHeadline Text="Portfolio"/>
        <div className="portfolio-box">
          <div className="portfolio-box-box">
            <div className="portfolio-box-box-box">
              <div className="portfolio-box-box-box-box"><Headline Text="Galerie"/></div>
            </div>
          <Galerie/>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
