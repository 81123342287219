import React from "react";
import './Button2.css';
import {Link} from "react-router-dom"

function Button2() {

  return (
    <div className="button2">
    <Link to="kontakt">Kontakt</Link>
    </div>
  );
}

export default Button2;
