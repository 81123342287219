import React from "react";
import './Logo.css';
import {Link} from "react-router-dom"

function Logo() {

  return (
    <div className="logo">
      <div className="logo-img"><div className="p1"><Link to="/">R</Link></div></div>
      <div className="logo-text"><div className="p2"><Link to="/">Dev</Link></div><div className="p3"><Link to="/">Ros</Link></div></div>
    </div>
  );
}

export default Logo;
