import React from "react";
import './GalerieBox.css';

function GalerieTag(probs) {

  return ( 
    <div className="galeriebox">
    <div className="galeriebox-tag">
        <div className="galeriebox-tag-tag-text">{probs.Text}</div>
        <div className="galeriebox-tag-tag-text2">{probs.Text2}</div>
    </div>
    <div className="galeriebox-box1">
    <a href={probs.Href}><img alt="portfolio" src={probs.Img}/></a>
    </div>
    </div>
  );
}

export default GalerieTag;
