import React from "react"
import {useState} from 'react'
import './Menu.css'
import {Link} from "react-router-dom"
import HamburgerMenuOpen from './source/hamburgermenuopen.png'
import HamburgerMenuClose from './source/hamburgermenuclose.png'
import Logo from './Logo'

function Menu() {

const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div>
      <div className="menu-ul">
        <div className="menu-li"><Link to="/">Domů</Link></div>
        <div className="menu-li"><Link to="pagefrontend">Front-end skills</Link></div>
        <div className="menu-li"><Link to="pagebackend">Back-end skills</Link></div>
        <div className="menu-li"><Link to="portfolio">Portfolio</Link></div>
        <div className="menu-li"><Link to="kontakt">Kontakt</Link></div>
      </div>  
        
      <div className="menu-ul-small"><img src={HamburgerMenuOpen} alt="logo" onClick={() => setToggleMenu(true)}/>
          {toggleMenu && (
                          <div className='menu-ul-small-overlay'>
                            <div className="menu-ul-small-overlay-logo">
                              <div className="menu-ul-small-overlay-logo-logo"><Logo/></div>
                              <img src={HamburgerMenuClose} className='menu-ul-small-overlay-close-img' alt="logo" onClick={() => setToggleMenu(false)} />
                            </div>
                            <div className='menu-ul-small-overlay-box'>           
                              <div className='menu-ul-small-overlay-link'><Link to="/" onClick={() => setToggleMenu(false)} >Domů</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="pagefrontend" onClick={() => setToggleMenu(false)}>Front-end skills</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="pagebackend" onClick={() => setToggleMenu(false)}>Back-end skills</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="portfolio" onClick={() => setToggleMenu(false)}>Portfolio</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="kontakt" onClick={() => setToggleMenu(false)}>Kontakt</Link></div>
                            </div>
                          </div>
                          )}
        </div>
    </div>
  );
}



export default Menu;
