import React from "react";
import './Copyright.css';

function Copyright() {

  return (
    <div className="copyright"> © 2022-2024 Všechna práva vyhrazena.</div>
  );
}

export default Copyright;
