import React from "react";
import './Header.css';
import Menu from './Menu';
import Logo from './Logo';

function Header() {

  return (
    <div>
      <div className="header">
      <Logo/>
      <Menu/>
      </div>
    </div>
  );
}

export default Header;
