import React from "react";
import './Footer.css';
import Footerlinks from './Footerlinks';
import Copyright from './Copyright';


function Footer() {

  return (
    <div>
      <div className="footer">
      <Footerlinks/>
      <Copyright/>
      </div>
    </div>
  );
}

export default Footer;
