import React from "react";
import './Footerlinks.css';

function Footerlinks() {

  return (
    <div className="footerlinks-ul">
      <div className="footerlinks-li"><a href="https://www.facebook.com/ross.machos">Facebook</a></div>
      <div className="footerlinks-li">Instagram</div>
      <div className="footerlinks-li">Youtube</div>
    </div>
  );
}

export default Footerlinks;
