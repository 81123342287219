import React from "react";
import './PageBackEnd.css';
import PageHeadline from './PageHeadline'
import Headline from './Headline'
import PageBox from './PageBox'
import SkillBar from './SkillBar'
import Img15 from './source/img15.png'
import Img18 from './source/img18.png'

function PageBackEnd() {

  return (
    <div>
      <PageHeadline Text="Back-End Skills"/>
      <div className="pagebackend-box">
        <div className="pagebackend-box-left" >
        <Headline Text="Co dělám ?"/>

        <PageBox
        Picture={Img15}
        Headline="Node.js Express"
        Text="Express je framework pro Node.js a byl vydaný jako bezplatný a open-source software pod licencí MIT. Je určen pro vytváření webových aplikací a API."
        />
        
        <PageBox
        Picture={Img18}
        Headline="Mongo DB"
        Text="Zdrojově dostupný databázový program. MongoDB je klasifikovaný jako databázový produkt NoSQL, využívá dokumenty podobné JSON s volitelnými schématy."
        />
        </div>
        <div className="pagebackend-box-right">
        <Headline Text="Coding skills"/>
        <SkillBar Name="Node.js Express" Value="10%" Graf="skillbar-box-graf-background-10"/>
        <SkillBar Name="Mongo DB" Value="10%" Graf="skillbar-box-graf-background-10"/>
        </div>
      </div>
    </div>
  );
}

export default PageBackEnd;
