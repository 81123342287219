import React from "react"
import './Kontakt.css'
import PageBox from "./PageBox"
import Headline from "./Headline"
import PageHeadline from "./PageHeadline"
import Formular from "./Formular"
import Img4 from './source/img4.png'
import Img5 from './source/img5.png'
import Img6 from './source/img6.png'
import Img7 from './source/img7.png'

function Kontakt() {

  return (
    <div>
    <PageHeadline Text="Kontakt"/>
    <div className="kontakt-box">
      <div className="kontakt-box-map"><img src={Img7} alt="img"/></div>
      <div className="kontakt-box-undermap">
        <div className="kontakt-box-kontakt">
        <PageBox
        Picture={Img4}
        Headline="+420 605 831 686"
        Text="Všechny dotazy k vašemu projektu a reklamacím dokáži vyřešit na zákaznické lince."
        />
        <PageBox
        Picture={Img5}
        Headline="Česká Republika"
        Text="Nejvíce se zaměřuji na projeky od zákazníků z České republiky"
        />
        <PageBox
        Picture={Img6}
        Headline="devros@devros.cz"
        Text="Vaše návrhy, nabídky a připomínky je možné zaslat na moji emailovou adresu"
        />
        </div>
        <div className="kontakt-box-formular">
          <Headline Text="Kontaktuj mě !"/>
          <Formular/>
          </div>
        </div>
      </div>
  </div>
  );
}

export default Kontakt;
