import React from "react";
import './SkillBar.css';

function SkillBar(probs) {

  return (
    <div className="skillbar-box">
      <div className="skillbar-box-name">{probs.Name}</div>
      <div className="skillbar-box-value">{probs.Value}</div>
      <div className="skillbar-box-graf">
        <div className={probs.Graf}></div>
      </div>
    </div>
  );
}

export default SkillBar;
