import React from "react";
import './Galerie.css';
import GalerieBox from './GalerieBox'
import GalerieBox2 from './GalerieBox2'
import  { useState } from 'react';
import Img20 from './source/img20.png'
import Img21 from './source/img21.png'
import Img22 from './source/img22.png'
import Img23 from './source/img23.png'
import Img24 from './source/img24.png'
import Img25 from './source/img25.png'
import Img26 from './source/img26.png'
import Img8  from './source/img8.png'
import Img9  from './source/img9.png'





function Galerie() {

const [TextSrc, setTextSrc] = useState({
textSrc:   "Design",
textSrc2:  "Future",

textSrc3:  "Design",
textSrc4:  "Architecture",

textSrc5:  "Design",
textSrc6:  "Portfolio",

textSrc7:  "Web",
textSrc8:  "Portfolio",

textSrc9:  "Web",
textSrc10: "Dj Řepa",

textSrc11: "Web",
textSrc12: "Žajgla",

textSrc100: "Future",
textSrc101: "Figma design",
textSrc102: "První projekt prostřednictvím Figma",
textSrc103: "15 000 zhlédnutí a 5000 kopií na Figma",

textSrc104: "Architecture",
textSrc105: "Figma design",
textSrc106: "Druhý projekt prostřednictvím Figma",
textSrc107: "1500 zhlédnutí a 500 kopií na Figma",

textSrc108: "Portfolio",
textSrc109: "Figma design",
textSrc110: "Třetí projekt prostřednictvím Figma",
textSrc111: "1700 zhlédnutí a 500 kopií na Figma",

textSrc112: "Personal Portfolio",
textSrc113: "React",
textSrc114: "První projekt prostřednictvím React",
textSrc115: "10 000 zhlédnutí a 3000 kopií na Figma",

textSrc116: "DJ Vítězslav Řepa",
textSrc117: "React",
textSrc118: "Druhý projekt prostřednictvím React",
textSrc119: "Osobní stránky moderátora a producenta",

textSrc120: "Sádrokartony Žajgla",
textSrc121: "React",
textSrc122: "Třetí projekt prostřednictvím React",
textSrc123: "Stránky stavební firmy",

imgSrc:  Img20,
imgSrc2: Img21,
imgSrc3: Img22,
imgSrc4: Img23,
imgSrc5: Img24,
imgSrc6: Img25,
imgSrc100: Img8,
imgSrc101: Img8,
imgSrc102: Img8,
imgSrc103: Img9,
imgSrc104: Img9,
imgSrc105: Img9,

hrefSrc100: "https://www.figma.com/community/file/1187715025843984704/portfolio-future-slider",
hrefSrc101: "https://www.figma.com/community/file/1187152702851399585/architecture-ros",
hrefSrc102: "https://www.figma.com/community/file/1188056409044192792/portfolio-personal",
hrefSrc103: "https://www.devros.cz/",
hrefSrc104: "https://www.djvitezslavrepa.cz/",
hrefSrc105: "https://www.sadrokartony-zajgla.cz",


textBox: "galerie-box6",

textDiv1: "galerie-box-on",
textDiv2: "galerie-box-on",
textDiv3: "galerie-box-on",
textDiv4: "galerie-box-on",
textDiv5: "galerie-box-on",
textDiv6: "galerie-box-on",

});


const handleClick1 = () => {

setTextSrc({

textSrc:   "Design",
textSrc2:  "Future",

textSrc3:  "Design",
textSrc4:  "Architecture",

textSrc5:  "Design",
textSrc6:  "Portfolio",

textSrc7:  "Web",
textSrc8:  "Portfolio",

textSrc9:  "Web",
textSrc10: "Dj Řepa",

textSrc11: "Web",
textSrc12: "Žajgla",

textSrc100: "Future",
textSrc101: "Figma design",
textSrc102: "První projekt prostřednictvím Figma",
textSrc103: "15 000 zhlédnutí a 5000 kopií na Figma",

textSrc104: "Architecture",
textSrc105: "Figma design",
textSrc106: "Druhý projekt prostřednictvím Figma",
textSrc107: "1500 zhlédnutí a 500 kopií na Figma",

textSrc108: "Portfolio",
textSrc109: "Figma design",
textSrc110: "Třetí projekt prostřednictvím Figma",
textSrc111: "1700 zhlédnutí a 500 kopií na Figma",

textSrc112: "Personal Portfolio",
textSrc113: "React",
textSrc114: "První projekt prostřednictvím React",
textSrc115: "10 000 zhlédnutí a 3000 kopií na Figma",

textSrc116: "DJ Vítězslav Řepa",
textSrc117: "React",
textSrc118: "Druhý projekt prostřednictvím React",
textSrc119: "Osobní stránky moderátora a producenta",

textSrc120: "Sádrokartony Žajgla",
textSrc121: "React",
textSrc122: "Třetí projekt prostřednictvím React",
textSrc123: "Stránky stavební firmy",

imgSrc:  Img20,
imgSrc2: Img21,
imgSrc3: Img22,
imgSrc4: Img23,
imgSrc5: Img24,
imgSrc6: Img25,
imgSrc100: Img8,
imgSrc101: Img8,
imgSrc102: Img8,
imgSrc103: Img9,
imgSrc104: Img9,
imgSrc105: Img9,

hrefSrc100: "https://www.figma.com/community/file/1187715025843984704/portfolio-future-slider",
hrefSrc101: "https://www.figma.com/community/file/1187152702851399585/architecture-ros",
hrefSrc102: "https://www.figma.com/community/file/1188056409044192792/portfolio-personal",
hrefSrc103: "https://www.devros.cz/",
hrefSrc104: "https://www.djvitezslavrepa.cz/",
hrefSrc105: "https://www.sadrokartony-zajgla.cz",

textBox: "galerie-box6",

textDiv1: "galerie-box-on",
textDiv2: "galerie-box-on",
textDiv3: "galerie-box-on",
textDiv4: "galerie-box-on",
textDiv5: "galerie-box-on",
textDiv6: "galerie-box-on",

});
};


const handleClick2 = () => {

setTextSrc({

textSrc:   "Design",
textSrc2:  "Future",

textSrc3:  "Design",
textSrc4:  "Architecture",

textSrc5:  "Design",
textSrc6:  "Portfolio",

textSrc7:  "Web",
textSrc8:  "Portfolio",

textSrc9:  "Web",
textSrc10: "Dj Řepa",

textSrc11: "Web",
textSrc12: "Žajgla",

textSrc100: "Future",
textSrc101: "Figma design",
textSrc102: "První projekt prostřednictvím Figma",
textSrc103: "15 000 zhlédnutí a 5000 kopií na Figma",

textSrc104: "Architecture",
textSrc105: "Figma design",
textSrc106: "Druhý projekt prostřednictvím Figma",
textSrc107: "1500 zhlédnutí a 500 kopií na Figma",

textSrc108: "Portfolio",
textSrc109: "Figma design",
textSrc110: "Třetí projekt prostřednictvím Figma",
textSrc111: "1700 zhlédnutí a 500 kopií na Figma",

textSrc112: "Personal Portfolio",
textSrc113: "React",
textSrc114: "První projekt prostřednictvím React",
textSrc115: "10 000 zhlédnutí a 3000 kopií na Figma",

textSrc116: "DJ Vítězslav Řepa",
textSrc117: "React",
textSrc118: "Druhý projekt prostřednictvím React",
textSrc119: "Osobní stránky moderátora a producenta",

textSrc120: "Sádrokartony Žajgla",
textSrc121: "React",
textSrc122: "Třetí projekt prostřednictvím React",
textSrc123: "Stránky stavební firmy",

imgSrc:  Img26,
imgSrc2: Img26,
imgSrc3: Img26,
imgSrc4: Img23,
imgSrc5: Img24,
imgSrc6: Img25,
imgSrc100: Img8,
imgSrc101: Img8,
imgSrc102: Img8,
imgSrc103: Img9,
imgSrc104: Img9,
imgSrc105: Img9,

hrefSrc100: "https://www.figma.com/community/file/1187715025843984704/portfolio-future-slider",
hrefSrc101: "https://www.figma.com/community/file/1187152702851399585/architecture-ros",
hrefSrc102: "https://www.figma.com/community/file/1188056409044192792/portfolio-personal",
hrefSrc103: "https://www.devros.cz/",
hrefSrc104: "https://www.djvitezslavrepa.cz/",
hrefSrc105: "https://www.sadrokartony-zajgla.cz",

textBox: "galerie-box3",

textDiv1: "galerie-box-off",
textDiv2: "galerie-box-off",
textDiv3: "galerie-box-off",
textDiv4: "galerie-box-on",
textDiv5: "galerie-box-on",
textDiv6: "galerie-box-on",

});
};


const handleClick3 = () => {

setTextSrc({
textSrc:   "",
textSrc2:  "",
textSrc3:  "",
textSrc4:  "",

textSrc5:  "",
textSrc6:  "",
textSrc7:  "",
textSrc8:  "",

textSrc9:  "",
textSrc10: "",
textSrc11: "",
textSrc12: "",

textSrc100: "",
textSrc101: "",
textSrc102: "",
textSrc103: "",

textSrc104: "",
textSrc105: "",
textSrc106: "",
textSrc107: "",

textSrc108: "",
textSrc109: "",
textSrc110: "",
textSrc111: "",

textSrc112: "",
textSrc113: "",
textSrc114: "",
textSrc115: "",

textSrc116: "",
textSrc117: "",
textSrc118: "",
textSrc119: "",

textSrc120: "",
textSrc121: "",
textSrc122: "",
textSrc123: "",

imgSrc:  Img26,
imgSrc2: Img26,
imgSrc3: Img26,
imgSrc4: Img26,
imgSrc5: Img26,
imgSrc6: Img26,
imgSrc100: Img8,
imgSrc101: Img8,
imgSrc102: Img8,
imgSrc103: Img8,
imgSrc104: Img8,
imgSrc105: Img8,

hrefSrc100: "https://www.figma.com/community/file/1187715025843984704/portfolio-future-slider",
hrefSrc101: "https://www.figma.com/community/file/1187152702851399585/architecture-ros",
hrefSrc102: "https://www.figma.com/community/file/1188056409044192792/portfolio-personal",
hrefSrc103: "https://www.devros.cz/",
hrefSrc104: "https://www.djvitezslavrepa.cz/",
hrefSrc105: "https://www.sadrokartony-zajgla.cz",

textBox: "galerie-box1",

textDiv1: "galerie-box-off",
textDiv2: "galerie-box-off",
textDiv3: "galerie-box-off",
textDiv4: "galerie-box-off",
textDiv5: "galerie-box-off",
textDiv6: "galerie-box-off",

});
};


const handleClick4 = () => {

setTextSrc({
textSrc:   "Design",
textSrc2:  "Future",

textSrc3:  "Design",
textSrc4:  "Architecture",

textSrc5:  "Design",
textSrc6:  "Portfolio",

textSrc7:  "Web",
textSrc8:  "Portfolio",

textSrc9:  "Web",
textSrc10: "Dj Řepa",

textSrc11: "Web",
textSrc12: "Žajgla",

textSrc100: "Future",
textSrc101: "Figma design",
textSrc102: "První projekt prostřednictvím Figma",
textSrc103: "15 000 zhlédnutí a 5000 kopií na Figma",

textSrc104: "Architecture",
textSrc105: "Figma design",
textSrc106: "Druhý projekt prostřednictvím Figma",
textSrc107: "1500 zhlédnutí a 500 kopií na Figma",

textSrc108: "Portfolio",
textSrc109: "Figma design",
textSrc110: "Třetí projekt prostřednictvím Figma",
textSrc111: "1700 zhlédnutí a 500 kopií na Figma",

textSrc112: "Personal Portfolio",
textSrc113: "React",
textSrc114: "První projekt prostřednictvím React",
textSrc115: "10 000 zhlédnutí a 3000 kopií na Figma",

textSrc116: "DJ Vítězslav Řepa",
textSrc117: "React",
textSrc118: "Druhý projekt prostřednictvím React",
textSrc119: "Osobní stránky moderátora a producenta",

textSrc120: "Sádrokartony Žajgla",
textSrc121: "React",
textSrc122: "Třetí projekt prostřednictvím React",
textSrc123: "Stránky stavební firmy",

imgSrc:  Img20,
imgSrc2: Img21,
imgSrc3: Img22,
imgSrc4: Img26,
imgSrc5: Img26,
imgSrc6: Img26,
imgSrc100: Img8,
imgSrc101: Img8,
imgSrc102: Img8,
imgSrc103: Img8,
imgSrc104: Img8,
imgSrc105: Img8,

hrefSrc100: "https://www.figma.com/community/file/1187715025843984704/portfolio-future-slider",
hrefSrc101: "https://www.figma.com/community/file/1187152702851399585/architecture-ros",
hrefSrc102: "https://www.figma.com/community/file/1188056409044192792/portfolio-personal",
hrefSrc103: "https://www.devros.cz/",
hrefSrc104: "https://www.djvitezslavrepa.cz/",
hrefSrc105: "https://www.sadrokartony-zajgla.cz",

textBox: "galerie-box3",

textDiv1: "galerie-box-on",
textDiv2: "galerie-box-on",
textDiv3: "galerie-box-on",
textDiv4: "galerie-box-off",
textDiv5: "galerie-box-off",
textDiv6: "galerie-box-off",

});
};

const handleClick5 = () => {

setTextSrc({
textSrc:   "",
textSrc2:  "",
textSrc3:  "",
textSrc4:  "",

textSrc5:  "",
textSrc6:  "",
textSrc7:  "",
textSrc8:  "",

textSrc9:  "",
textSrc10: "",
textSrc11: "",
textSrc12: "",

textSrc100: "",
textSrc101: "",
textSrc102: "",
textSrc103: "",

textSrc104: "",
textSrc105: "",
textSrc106: "",
textSrc107: "",

textSrc108: "",
textSrc109: "",
textSrc110: "",
textSrc111: "",

textSrc112: "",
textSrc113: "",
textSrc114: "",
textSrc115: "",

textSrc116: "",
textSrc117: "",
textSrc118: "",
textSrc119: "",

textSrc120: "",
textSrc121: "",
textSrc122: "",
textSrc123: "",


imgSrc:  Img26,
imgSrc2: Img26,
imgSrc3: Img26,
imgSrc4: Img26,
imgSrc5: Img26,
imgSrc6: Img26,
imgSrc100: Img8,
imgSrc101: Img8,
imgSrc102: Img8,
imgSrc103: Img8,
imgSrc104: Img8,
imgSrc105: Img8,

hrefSrc100: "https://www.figma.com/community/file/1187715025843984704/portfolio-future-slider",
hrefSrc101: "https://www.figma.com/community/file/1187152702851399585/architecture-ros",
hrefSrc102: "https://www.figma.com/community/file/1188056409044192792/portfolio-personal",
hrefSrc103: "https://www.devros.cz/",
hrefSrc104: "https://www.djvitezslavrepa.cz/",
hrefSrc105: "https://www.sadrokartony-zajgla.cz",

textBox: "galerie-box1",

textDiv1: "galerie-box-off",
textDiv2: "galerie-box-off",
textDiv3: "galerie-box-off",
textDiv4: "galerie-box-off",
textDiv5: "galerie-box-off",
textDiv6: "galerie-box-off",
});
};

  return (
    <div>
    <div className="galeriemenu-ul">
    <div className="galeriemenu-li" onClick={handleClick1}>All</div>
    <div className="galeriemenu-li" onClick={handleClick2}>Web</div>
    <div className="galeriemenu-li" onClick={handleClick3}>Animace</div>
    <div className="galeriemenu-li" onClick={handleClick4}>Design</div>
    <div className="galeriemenu-li" onClick={handleClick5}>Photo</div>
    </div>
    
    <div className="galerie">
      <div className={TextSrc.textBox}>
        <div className={TextSrc.textDiv1}><GalerieBox Text={TextSrc.textSrc} Text2={TextSrc.textSrc2} Img={TextSrc.imgSrc} Href={TextSrc.hrefSrc100}> </GalerieBox></div>
        <div className={TextSrc.textDiv1}><GalerieBox2 TextA={TextSrc.textSrc100} TextB={TextSrc.textSrc101} TextC={TextSrc.textSrc102} TextD={TextSrc.textSrc103} ImgAvatar={TextSrc.imgSrc100} ></GalerieBox2></div>

        <div className={TextSrc.textDiv2}><GalerieBox Text={TextSrc.textSrc3} Text2={TextSrc.textSrc4} Img={TextSrc.imgSrc2} Href={TextSrc.hrefSrc101}> </GalerieBox></div>
        <div className={TextSrc.textDiv2}><GalerieBox2 TextA={TextSrc.textSrc104} TextB={TextSrc.textSrc105} TextC={TextSrc.textSrc106} TextD={TextSrc.textSrc107} ImgAvatar={TextSrc.imgSrc101}></GalerieBox2></div>

        <div className={TextSrc.textDiv3}><GalerieBox Text={TextSrc.textSrc5} Text2={TextSrc.textSrc6} Img={TextSrc.imgSrc3} Href={TextSrc.hrefSrc102}> </GalerieBox></div>
        <div className={TextSrc.textDiv3}><GalerieBox2 TextA={TextSrc.textSrc108} TextB={TextSrc.textSrc109} TextC={TextSrc.textSrc110} TextD={TextSrc.textSrc111} ImgAvatar={TextSrc.imgSrc102}></GalerieBox2></div>

        <div className={TextSrc.textDiv4}><GalerieBox Text={TextSrc.textSrc7} Text2={TextSrc.textSrc8} Img={TextSrc.imgSrc4} Href={TextSrc.hrefSrc103}> </GalerieBox></div>
        <div className={TextSrc.textDiv4}><GalerieBox2 TextA={TextSrc.textSrc112} TextB={TextSrc.textSrc113} TextC={TextSrc.textSrc114} TextD={TextSrc.textSrc115} ImgAvatar={TextSrc.imgSrc103}></GalerieBox2></div>

        <div className={TextSrc.textDiv5}><GalerieBox Text={TextSrc.textSrc9} Text2={TextSrc.textSrc10} Img={TextSrc.imgSrc5} Href={TextSrc.hrefSrc104}> </GalerieBox></div>
        <div className={TextSrc.textDiv5}><GalerieBox2 TextA={TextSrc.textSrc116} TextB={TextSrc.textSrc117} TextC={TextSrc.textSrc118} TextD={TextSrc.textSrc119} ImgAvatar={TextSrc.imgSrc104}></GalerieBox2></div>

        <div className={TextSrc.textDiv6}><GalerieBox Text={TextSrc.textSrc11} Text2={TextSrc.textSrc12} Img={TextSrc.imgSrc6} Href={TextSrc.hrefSrc105}> </GalerieBox></div>
        <div className={TextSrc.textDiv6}><GalerieBox2 TextA={TextSrc.textSrc120} TextB={TextSrc.textSrc121} TextC={TextSrc.textSrc122} TextD={TextSrc.textSrc123} ImgAvatar={TextSrc.imgSrc105}></GalerieBox2></div>
      </div>
    </div>
    </div>
  );
}

export default Galerie;
