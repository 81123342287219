import React from "react";
import './GalerieBox2.css';

function GalerieTag(probs) {

  return (
    <div className="galeriebox2">
        <div className="galeriebox2-box1">
          <div className="galeriebox2-box2">{probs.TextA}</div>
          <div className="galeriebox2-box3">{probs.TextB}</div>
          <div className="galeriebox2-box4">{probs.TextC}</div>
          <div className="galeriebox2-box5">
            <div className="galeriebox2-box6"><img alt="portfolio" src={probs.ImgAvatar}/></div>
            <div className="galeriebox2-box7">{probs.TextD}</div>
          </div>
        </div>
    </div>
  );
}

export default GalerieTag;
