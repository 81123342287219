import React from "react";
import './Button3.css';

function Button3() {

  return (
    <div className="button3">
    <input type="submit" className="button3-text" value="Odeslat"></input>
    </div>
  );
}

export default Button3;
