import React from "react";
import './PageHome.css';
import Button1 from './Button1'
import Button2 from './Button2'
import movie from './source/video.webm';

function PageHome() {

  return (
      <div className="page-home">
        <div className="page-home-box">
          <div className="page-home-box-logo"> 
            <div className="page-home-box-logo-background"><video autoPlay playsInline muted loop className="movie" src={movie}/></div>
          </div>
          <div className="page-home-box-text">
            <div className="page-home-box-text-h2">Full Stack Developer</div>
            <div className="page-home-box-text-h1">DevRos</div>
            <div className="page-home-box-text-p">Potřebujete vytvořit webové stránky ? Dohlížím na design od návrhu až po realizaci. Technickou stránku webu zajistišťuji tak, aby všechno běželo přesně jak má. Kdyby Vás portfolio zaujalo - napište mi !</div>
            <div className="page-home-box-text-buttons"><Button1/><Button2/></div>
          </div>
        </div>
      </div>
  );
}

export default PageHome;
