import React from "react";
import './PageBox.css';

function PageBox(probs) {

  return (
    <div className="pagebox-box">
      <div className="pagebox-box-picture"><img src={probs.Picture} alt="logo"/></div>
      <div className="pagebox-box-headline">{probs.Headline}</div>
      <div className="pagebox-box-text">{probs.Text}</div>
    </div>
  );
}

export default PageBox;
