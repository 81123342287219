import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {useState, useEffect} from 'react'
import './App.css';
import Header from './Header'
import Footer from './Footer'
import PageHome from './PageHome'
import PageBackEnd from './PageBackEnd'
import PageFrontEnd from './PageFrontEnd'
import Portfolio from './Portfolio'
import Kontakt from './Kontakt';
import { PuffLoader } from "react-spinners";


function App() {

  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true) 
    setTimeout(()=>{
    setLoading(false)
    },3000)},[])

  return (
     
    <div className="loader">
    {loading ? (
      <PuffLoader size={150} 
                  color="rgba(0, 124, 237)" 
                  loading={loading}/>
      ) : (
       
    <div className="app">
    <Router>
      <Header/>
        <Routes>
        <Route path="/" element={<PageHome/>}/>
        <Route path="*" element={<PageHome/>} />
        <Route path="/pagefrontend" element={<PageFrontEnd/>}/>
        <Route path="/pagebackend" element={<PageBackEnd/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/kontakt" element={<Kontakt/>}/>
        </Routes>
      <Footer/>
    </Router>
    </div>
   )}
   </div>
 ) ;
}

export default App;