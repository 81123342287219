import React from "react";
import './PageHeadline.css';

function PageHeadline(probs) {

  return (
    <div className="pageheadline">
      <div className="pageheadline-text">{probs.Text}</div>
    </div>
  );
}

export default PageHeadline;
