import React from "react";
import './PageFrontEnd.css';
import PageHeadline from './PageHeadline'
import Headline from './Headline'
import PageBox from './PageBox'
import SkillBar from './SkillBar'
import Img1 from './source/img1.png'
import Img2 from './source/img2.png'
import Img3 from './source/img3.png'

function PageFrontEnd() {

  return (
    <div>
      <PageHeadline Text="Front-End Skills"/>
      <div className="pagefrontend-box">
        <div className="pagefrontend-box-left" >
        <Headline Text="Co dělám ?"/>

        <PageBox
        Picture={Img1}
        Headline="UI / UX Design"
        Text="UX je jednodušeně řečeno vaše uživatelská zkušenost s aplikací a UI její vnější stránka či kostra, která vám pomáhá aplikaci správně používat."
        />
        
        <PageBox
        Picture={Img2}
        Headline="HTML, CSS, JavaScript"
        Text="Front-end vývojář vytváří kód uživatelského rozhraní pro webové stránky a aplikace. Řídí interakci mezi uživatelem a danou stránkou"
        />
       
        <PageBox
        Picture={Img3}
        Headline="Sass, Green Sock, React"
        Text="Propracované technologie mají na starosti funkčnost, intuitivnost a jednoduchou ovladatelnost vizuálního prostředí stránky."
        />
        </div>
        <div className="pagefrontend-box-right">
        <Headline Text="Coding skills"/>
        <div className="pagefrontend-box-right-bars">
        <SkillBar Name="HTML" Value="90%" Graf="skillbar-box-graf-background-90"/>
        <SkillBar Name="CSS" Value="80%" Graf="skillbar-box-graf-background-80"/>
        <SkillBar Name="JavaScript" Value="10%" Graf="skillbar-box-graf-background-10"/>
        <SkillBar Name="React" Value="20%" Graf="skillbar-box-graf-background-20"/>
        <SkillBar Name="Sass" Value="10%" Graf="skillbar-box-graf-background-10"/>
        <SkillBar Name="GreenShock" Value="10%" Graf="skillbar-box-graf-background-10"/>
        </div>
        </div>
      </div>
    </div>
  );
}

export default PageFrontEnd;
