import React from "react";
import './Headline.css';

function Headline(probs) {

  return (
    <div className="headline-box">
      <div className="headline-box-h1">{probs.Text}</div>
      <div className="headline-box-border-grey"></div>
      <div className="headline-box-border-blue"></div>
    </div>
  );
}

export default Headline;
