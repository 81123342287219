import emailjs from '@emailjs/browser';
import React from 'react';
import { useRef } from "react";
import Button3 from './Button3';
import './Formular.css'
 
export const Formular = () => {

    const form = useRef();

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_aft86zf', 'template_npok5g3', form.current, 'IO9hyFSk5adaJrgeB')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }


  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
              <div className="kontakt-box-formular-grid">
                  <div className="kontakt-box-formular-grid-1">
                      <input type="text" className="kontakt-box-formular-grid-1-text" placeholder="&nbsp;&nbsp;Jméno a Příjmení" name="name"/>
                  </div>
                  <div className="kontakt-box-formular-grid-2">
                      <input type="email" className="kontakt-box-formular-grid-2-text" placeholder="&nbsp;&nbsp;Email Address" name="email"/>
                  </div>
                  <div className="kontakt-box-formular-grid-3" >
                    <select className="kontakt-box-formular-grid-3-text" name="subject" type="subject">
                      <option name="subject" value="Spolupráce">&nbsp;&nbsp;Spolupráce</option>
                      <option name="subject" value="Reklamace">&nbsp;&nbsp;Reklamace</option>
                      <option name="subject" value="Všeobecný dotaz">&nbsp;&nbsp;Všeobecný dotaz</option>
                    </select>
                  </div>
                  <div className="kontakt-box-formular-grid-4">
                      <textarea className="kontakt-box-formular-grid-4-text" placeholder="&nbsp;&nbsp;Napiš mi" name="message"></textarea>
                  </div>
                  <div className="kontakt-box-formular-button"><Button3/></div>
              </div>
      </form>
    </div>

  );
}

export default Formular;
